// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.animal-list_cell__Boix3 {
  padding: 6px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Animals/animal-list.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB","sourcesContent":[".cell {\n  padding: 6px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cell": `animal-list_cell__Boix3`
};
export default ___CSS_LOADER_EXPORT___;
