// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datePickerReactPackage {
    padding: 9px 10px;
    border-radius: 5px;
    background-color: #ffffff;
    color: black;
    cursor: pointer;
    border: 1px solid black;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #B1040E !important;
     color: #ffffff !important;
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #B1040E !important;
    color: #ffffff !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/dateStyles.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,uBAAuB;EACzB;;EAEA;;;;;;;;;;;;IAYE,oCAAoC;KACnC,yBAAyB;EAC5B;EACA;;;;IAIE,oCAAoC;IACpC,yBAAyB;EAC3B","sourcesContent":[".datePickerReactPackage {\n    padding: 9px 10px;\n    border-radius: 5px;\n    background-color: #ffffff;\n    color: black;\n    cursor: pointer;\n    border: 1px solid black;\n  }\n  \n  .react-datepicker__day--selected,\n  .react-datepicker__day--in-selecting-range,\n  .react-datepicker__day--in-range,\n  .react-datepicker__month-text--selected,\n  .react-datepicker__month-text--in-selecting-range,\n  .react-datepicker__month-text--in-range,\n  .react-datepicker__quarter-text--selected,\n  .react-datepicker__quarter-text--in-selecting-range,\n  .react-datepicker__quarter-text--in-range,\n  .react-datepicker__year-text--selected,\n  .react-datepicker__year-text--in-selecting-range,\n  .react-datepicker__year-text--in-range {\n    background-color: #B1040E !important;\n     color: #ffffff !important;\n  }\n  .react-datepicker__day--keyboard-selected:hover,\n  .react-datepicker__month-text--keyboard-selected:hover,\n  .react-datepicker__quarter-text--keyboard-selected:hover,\n  .react-datepicker__year-text--keyboard-selected:hover {\n    background-color: #B1040E !important;\n    color: #ffffff !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
