// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sideMenu{
    height: 85vh;
    min-height: 71vh;
    max-height: 78vh;
    overflow-y: scroll;

}

@media screen and (max-width: 1280px) {
  .sideMenu {
    height: 65vh;
    min-height: 45vh ;
    max-height: 55vh ;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sideMenuStyles.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;EACE;IACE,YAAY;IACZ,iBAAiB;IACjB,iBAAiB;EACnB;AACF","sourcesContent":["\n.sideMenu{\n    height: 85vh;\n    min-height: 71vh;\n    max-height: 78vh;\n    overflow-y: scroll;\n\n}\n\n@media screen and (max-width: 1280px) {\n  .sideMenu {\n    height: 65vh;\n    min-height: 45vh ;\n    max-height: 55vh ;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
