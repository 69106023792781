// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading {
  font-family: "Poppins-Medium";
  color: #000000;
  text-align: center;
}
.subHeading {
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #000000;
  font-weight: 600;
  
}
.emailLogin {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d0bcbc;
  line-height: 0.1em;
  font-family: "Poppins-Medium";
  font-size: 12px;
}
.borderLine {
  background: #fff;
  padding: 0 10px;
  font-family: "Poppins-Medium";
  font-size: 12px;
  font-weight: 600
}
.skipText{
    font-family: 'MetropolisSemiBold';
    color: #FA8842;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}
.checkboxText{
    font-family: 'Poppins-Regular';
    font-size: 12px;
    color: #000000;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Login/login.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,6BAA6B;EAC7B,eAAe;EACf,cAAc;EACd,gBAAgB;;AAElB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,gCAAgC;EAChC,kBAAkB;EAClB,6BAA6B;EAC7B,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,6BAA6B;EAC7B,eAAe;EACf;AACF;AACA;IACI,iCAAiC;IACjC,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,8BAA8B;IAC9B,eAAe;IACf,cAAc;AAClB","sourcesContent":[".heading {\n  font-family: \"Poppins-Medium\";\n  color: #000000;\n  text-align: center;\n}\n.subHeading {\n  font-family: \"Poppins-Medium\";\n  font-size: 12px;\n  color: #000000;\n  font-weight: 600;\n  \n}\n.emailLogin {\n  width: 100%;\n  text-align: center;\n  border-bottom: 1px solid #d0bcbc;\n  line-height: 0.1em;\n  font-family: \"Poppins-Medium\";\n  font-size: 12px;\n}\n.borderLine {\n  background: #fff;\n  padding: 0 10px;\n  font-family: \"Poppins-Medium\";\n  font-size: 12px;\n  font-weight: 600\n}\n.skipText{\n    font-family: 'MetropolisSemiBold';\n    color: #FA8842;\n    font-size: 12px;\n    text-align: center;\n    cursor: pointer;\n}\n.checkboxText{\n    font-family: 'Poppins-Regular';\n    font-size: 12px;\n    color: #000000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
