import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";
import CustomTable from "../../components/Table";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CustomModal from "../../components/Modal";
import { apiRequest } from "../../services/api-request";
import { Colors } from "../../constants/Colors";
import Card from "../../components/Card";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Pagination from "../../components/Pagination";
import { useAppContext } from "../../context/AppContext";
import CustomDialog from "../../components/ConfirmationModal";
import FileUploader from "../../components/FileUploader/file-uploder";

const label = { inputProps: { "aria-label": "switch" } };
const dashboardHeader = [
  {
    id: 1,
    title: "ID",
  },
  {
    id: 2,
    title: "Image",
  },
  {
    id: 3,
    title: "Product Name",
  },
  {
    id: 4,
    title: "Company Name",
  },
  // {
  //   id: 5,
  //   title: "Price Per Unit",
  // },
  {
    id: 6,
    title: "Active",
  },
  {
    id: 7,
    title: "Actions",
  },
];

const FeedNutrition = (props) => {
  const { user = {} } = useAppContext();

  const [feedNutritionList, setFeedNutritionList] = useState({
    data: [],
    totalCount: "",
  });
  const [currentFeedDetails, setCurrentFeedDetails] = useState({});

  const limit = 10;
  const [skip, setSkip] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // Add this line
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    getFeedNutrition("", skip);
  }, [skip]);

  const getFeedNutrition = (searchText, skip) => {
    const payload = {
      skip: skip,
      limit: limit,
      ...(searchText && {
        name: searchText,
      }),
    };

    const url =
      user?.role?.code === "admin"
        ? `master/hs/list`
        : `madmin/master/feed/list`;
    apiRequest({
      url,
      data: payload,
      method: "POST",
    })
      .then((res) => {
        const modifiedData = res?.data?.map((feedNutritionList) => ({
          id: feedNutritionList?.id,
          name: feedNutritionList?.name,
          companyName: feedNutritionList?.company_name,
          price: feedNutritionList?.price,
          image: (
            <img
              src={
                feedNutritionList?.image
                  ? `${feedNutritionList?.image}`
                  : require("../../assets/fn.png")
              }
              width={50}
              height={50}
            />
          ),
          // actions: <ActionButtons />,
        }));
        setFeedNutritionList({
          data: modifiedData,
          totalCount: res?.total_count,
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handlePageChange = (pageNumber) => {
    const newSkip = (pageNumber - 1) * limit;
    setSkip(newSkip);
  };

  const onClickModalClose = (isSuccess) => {
    if (isSuccess) {
      // If the modal was closed successfully after adding/editing, refresh the health service list
      getFeedNutrition();
    }
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };
  const handleAddButtonClick = () => {
    setCurrentFeedDetails({});
    setIsAddModalOpen(true);
  };

  const handleEditButtonClick = (hsDetails) => {
    setCurrentFeedDetails({});
    setIsEditModalOpen(true);
  };

  const handleActiveInactiveSwitch = (e, hsId) => {
    const hsIndex = feedNutritionList?.findIndex((hs) => hs?.id === hsId);
    const cloneHsData = JSON.parse(JSON.stringify(feedNutritionList));
    if (hsIndex > -1) {
      cloneHsData[hsIndex].active = e?.target?.checked;
      setFeedNutritionList([...cloneHsData]);
      updateHSStatus(e?.target?.checked, hsId);
    }
  };

  const updateHSStatus = (checked, hsId) => {
    const payload = {
      active: checked,
    };
    apiRequest({
      url: `master/update-feed/${hsId}`, // Change here
      data: payload,
      method: "PUT",
    })
      .then((res) => {
        getFeedNutrition();
        props.onClickModalClose(true);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const ActionButtons = (row) => {
    return (
      <Grid container alignItems={"center"} gap={2}>
        {/* <Grid item>
          <CustomButton
            title={"Delete"}
            backgroundColor={"#E1E1E1"}
            textColor={"#111A45"}
            fontSize={"12px"}
            width={52}
            height={22}
          />
        </Grid> */}
        <Grid item>
          <CustomButton
            title={"Edit"}
            handleButtonClick={() => {
              handleEditButtonClick(row);
              setCurrentFeedDetails(row);
            }}
            backgroundColor={"#E1E1E1"}
            textColor={"#111A45"}
            fontSize={"12px"}
            width={52}
            height={22}
          />
        </Grid>
      </Grid>
    );
  };

  const TableDataUi = () => {
    return feedNutritionList?.data?.map((row) => (
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.id}
          </Typography>
        </TableCell>
        <TableCell>{row.image}</TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {" "}
            {row.name}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {" "}
            {row.companyName}
          </Typography>
        </TableCell>
        {/* <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}>
            {" "}
            {row.price}
          </Typography>
        </TableCell> */}
        <TableCell>
          <Switch {...label} defaultChecked color={"error"} />
        </TableCell>
        <TableCell>{ActionButtons(row)}</TableCell>
      </TableRow>
    ));
  };

  const Add = (props) => {
    const { isEdit, onClickModalClose, currentFeedDetails } = props;

    const onClickCancel = () => {
      onClickModalClose(false);
    };
    const schema = yup
      .object({
        name: yup.string().required("Enter Product Name is required"),
        companyName: yup.string().required("Enter Company Name is required"),
        // price: yup.string().required("Price per Unit is required"),
      })
      .required();

    const getEditUserDetails = (isEdit, currentFeedDetails) => {
      if (!isEdit || !currentFeedDetails) {
        return { name: "", companyName: "", price: "" };
      } else {
        return {
          name: currentFeedDetails.name || "",
          companyName: currentFeedDetails.companyName || "",
          price: currentFeedDetails.price || "",
        };
      }
    };

    const { handleSubmit, control, getValues } = useForm({
      resolver: yupResolver(schema),
      defaultValues: getEditUserDetails(isEdit, currentFeedDetails), // Use 'feedNutritionList' directly here
    });
    const onSubmit = (data) => {
      if (isEdit && currentFeedDetails?.id) {
        const formData = getValues();
        const payload = {
          name: formData?.name,
          company_name: formData?.companyName,
          price: formData?.price,
        };
        apiRequest({
          url: `master/update-feed/${currentFeedDetails?.id}`,
          data: payload,
          method: "PUT",
        })
          .then((res) => {
            props.onClickModalClose(true);
          })
          .catch((err) => {
            // alert(err?.message, "error");
          });
      } else {
        const payload = {
          name: data?.name,
          company_name: data?.companyName,
          // price: Number(data?.price),
        };
        const URL =
          user?.role?.code === "admin"
            ? "master/add-feed"
            : "madmin/master/add-feed";
        apiRequest({
          url: URL,
          data: payload,
          method: "POST",
        })
          .then((res) => {
            props.onClickModalClose(true);
            if (res?.success === true) {
              alert("Added Successfully");
            }
            // props.getFeedNutrition();
          })
          .catch((err) => {
            console.log(err);
            // alert(err?.response?.data?.message, "error");
          });
      }
    };

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid
            container
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            mt={3}
            gap={3}
            width={"100%"}
          >
            <Grid item width={"45%"}>
              <Controller
                name="name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    type={"text"}
                    inputLabel={"Enter Product Name"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
            <Grid item width={"45%"}>
              <Controller
                name="companyName"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    type={"text"}
                    inputLabel={"Enter Company Name"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"center"}
            mt={3}
            gap={3}
            width={"100%"}
          >
            <Grid item width={"45%"}>
              <FileUploader />
            </Grid>
            {/* <Grid item width={"45%"}>
              <Controller
                name="price"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <CustomInput
                    {...field}
                    type={"text"}
                    inputLabel={"Price per Unit"}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Grid
            container
            display={"flex"}
            alignItems={"baseline"}
            justifyContent={"flex-end"}
            mt={3}
            gap={3}
            width={"100%"}
          >
            <Grid item>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                gap={2}
              >
                <Box>
                  <CustomButton
                    title={`Cancel`}
                    variant={"outlined"}
                    handleButtonClick={props.onClickModalClose}
                    backgroundColor={Colors.white}
                    textColor={Colors.headerColor}
                    width={100}
                    height={34}
                    borderColor={Colors.headerColor}
                    textFontSize={14}
                  />
                </Box>
                <Box>
                  <CustomButton
                    title={`Save`}
                    backgroundColor={Colors.headerColor}
                    textColor={Colors.white}
                    width={100}
                    height={34}
                    borderColor={Colors.headerColor}
                    textFontSize={14}
                    type={"submit"}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const searchText = (e) => {
    const searchText = e.target.value;
    getFeedNutrition(searchText);
  };
  return (
    <Box>
      {/* Add Health Service Modal */}
      <CustomDialog
        open={isAddModalOpen}
        width={"500px"}
        onClose={() => onClickModalClose(false)}
        title={"Add Feed & Nutrition"}
      >
        <Add
          onClickModalClose={onClickModalClose}
          getFeedNutrition={getFeedNutrition}
        />
      </CustomDialog>

      {/* Edit Health Service Modal */}

      <CustomModal
        openModal={isEditModalOpen}
        handleModalClose={() => onClickModalClose(false)}
        title={"Edit Feed & Nutrition"}
      >
        <Add
          currentFeedDetails={currentFeedDetails}
          onClickModalClose={onClickModalClose}
          isEdit={Boolean(currentFeedDetails?.id)}
        />
      </CustomModal>

      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            fontFamily={"Poppins-Medium"}
            fontSize={20}
            color={Colors.textColor}
          >
            Feed & Nutrition
          </Typography>
          <Typography
            fontFamily={"Poppins-Regular"}
            fontSize={13}
            color={Colors.textColor}
          >
            Masters {`>`} Feed & Nutrition
          </Typography>
        </Grid>
        <Grid item>
          <CustomButton
            title={"+ Add Feed & Nutrition"}
            handleButtonClick={handleAddButtonClick} // Change onAddButtonPress to handleAddButtonClick
            backgroundColor={Colors.headerColor}
            textColor={Colors.white}
            width={185}
            height={34}
            textFontSize={14}
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <Card>
          <Grid container alignItems={"center"} gap={2}>
            <Grid item md={3}>
              <CustomInput
                onChange={(e) => searchText(e)}
                placeholder={"Search"}
                leftIcon={
                  <SearchOutlinedIcon style={{ color: Colors.textColor }} />
                }
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <CustomTable
              headerData={dashboardHeader}
              tableData={feedNutritionList}
            >
              <TableDataUi />
            </CustomTable>
            <Box mt={2} display={"flex"} justifyContent={"right"}>
              {feedNutritionList?.totalCount > 10 && (
                <Pagination
                  totalCount={Number(feedNutritionList?.totalCount)}
                  skip={skip}
                  limit={limit}
                  onPageChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default FeedNutrition;
