import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, TableCell, TableRow } from "@mui/material";
import { Colors } from "../../constants/Colors";
import Card from "../../components/Card";
import CustomTable from "../../components/Table";
import CustomButton from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../services/api-request";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CustomInput from "../../components/Input";
import ConfirmationDialog from "../../components/ConfirmationModal";

const dashboardHeader = [
  {
    id: 1,
    title: "Request ID",
  },
  {
    id: 2,
    title: "Date",
  },
  {
    id: 3,
    title: "Partner",
  },
  {
    id: 4,
    title: "Buy Request Details",
  },
  {
    id: 4,
    title: "Buying Price",
  },
  {
    id: 4,
    title: "No of Sell Requests",
  },
  {
    id: 5,
    title: "Status",
  },
  {
    id: 6,
    title: "Action",
  },
];

const RequestDetails = () => {
  const { requestType, requestId } = useParams();
  
  const navigate = useNavigate();

  const [buyRequestList, setBuyRequestList] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);

  // useEffect(() => {
  //   getBuyRequests();
  // }, []);

  useEffect(() => {
    if (requestId) {
      getBuyRequestById(requestId);
    }
  }, [requestId]);

  // const getBuyRequests = () => {
  //   const payload = {
  //     type: "SELL",
  //   };
  //   apiRequest({
  //     url: `order/list`,
  //     data: payload,
  //     method: "POST",
  //   })
  //     .then((res) => {
  //       const modifiedData = res?.data?.map((sellRequest) => ({
  //         id: sellRequest?.id,
  //         reqOn: sellRequest?.created_on,
  //         reqByName: sellRequest?.seller_details?.name,
  //         reqByUserId: sellRequest?.seller_details?.user_id,
  //         reqByRole: sellRequest?.seller_details?.type,
  //         reqName: sellRequest?.details?.display_name,
  //         orderDetails: `${sellRequest?.details?.category?.display_name} | ${sellRequest?.details?.breed?.display_name} | ${sellRequest?.details?.type?.display_name}`,
  //         orderDetails1: `${sellRequest?.details?.details?.age_in_months} Months | ${sellRequest?.details?.details?.weight} Kg | ${sellRequest?.details?.details?.no_of_calvings} | ${sellRequest?.details?.details?.milking_quantity} Ltr`,
  //         sellingPrice: sellRequest?.seller_price,
  //         noOfSellRequests: 0,
  //         status: sellRequest?.status,
  //       }));
  //       setBuyRequestList({
  //         data: modifiedData,
  //         totalCount: 0,
  //       });
  //     })
  //     .catch((err) => {
  //       alert(err);
  //     });
  // };

  const getBuyRequestById = (requestId) => {
    const payload = {};
    apiRequest({
      url: `request/details/${requestId}`,
      data: payload,
      method: "GET",
    })
      .then((res) => {
        setRequestDetails(res?.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const TableDataUi = () => {
    return buyRequestList?.data?.map((row) => (
      <TableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <Typography
            fontFamily={"Poppins-Medium"}
            color={Colors.headerColor}
            fontSize={13}
          >
            {row.id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={13}
          >
            {row.reqOn}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Medium"}
            color={Colors.headerColor}
            fontSize={13}
            sx={{ textDecorationLine: "underline", cursor: "pointer" }}
            onClick={() => {
              requestType === "buy"
                ? navigate("/UserView/traders")
                : navigate("/UserView/farmers");
            }}
          >
            {row?.reqByName}
          </Typography>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={13}
          >
            {row?.reqByRole}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.orderDetails}
          </Typography>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.orderDetails1}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.buyingPrice}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.noOfSellRequests}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontFamily={"Poppins-Regular"}
            color={Colors.textColor}
            fontSize={12}
          >
            {row.status}
          </Typography>
        </TableCell>
        <TableCell>
          <CustomButton
            title={`Confirm`}
            handleButtonClick={() => {
              setShowRequestConfirmation(true);
            }}
            backgroundColor={Colors.white}
            textColor={Colors.headerColor}
            width={110}
            height={30}
            borderColor={Colors.headerColor}
            textFontSize={14}
          />
        </TableCell>
      </TableRow>
    ));
  };

  const handleInputChange = (event) => {
    // getVillagesList(
    //   userDetails?.district_id,
    //   userDetails?.controlling_villages,
    //   selectedMandal,
    //   event?.target?.value
    // );
  };

  const confirmRequest = () => {
    const payload = {
      order_id: requestId,
      partner_id: requestDetails?.seller_id,
      price: requestDetails?.seller_price,
    };

    apiRequest({
      url: `order/${requestType}/confirm`,
      data: payload,
      method: "PUT",
    })
      .then((res) => {
        // setRequestDetails(res?.data);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleRequestConfirmationClose = () => {
    setShowRequestConfirmation(false);
  };

  const handleYes = () => {
    confirmRequest();
  };

  return (
    <Box>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            fontFamily={"Poppins-Medium"}
            fontSize={20}
            color={Colors.textColor}
          >
            {requestType === "buy" ? "Buy" : "Sell"} Process
          </Typography>
          <Typography
            fontFamily={"Poppins-Regular"}
            fontSize={13}
            color={Colors.textColor}
          >
            {`Requests > ${requestType === "buy" ? "Buy" : "Sell"} > View`}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Card>
          <Grid
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            gap={4}
          >
            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Seller Name : 402536
              </Typography>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Seller Mobile : 9603263099
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Seller ACE : ACE Name
              </Typography>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Seller Location : Seller Location comes
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box mt={2}>
        <Card>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Request Id : 402536
              </Typography>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Request Details : Cow | Gir | Milking Animal
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Request Id : 402536
              </Typography>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Request Details : Cow | Gir | Milking Animal
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Date & Time : 22 Aug 2022 | 09:23
              </Typography>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.textColor}
                fontSize={13}
                m={1}
              >
                Additional Info : 46 Months | 250 Kg | 02
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box mt={2}>
        <Card>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography
                fontFamily={"Poppins-Regular"}
                color={Colors.headerColor}
                fontSize={13}
                m={1}
              >
                Matched Partners
              </Typography>
            </Grid>

            <Grid item width={250}>
              <CustomInput
                placeholder={"Search"}
                leftIcon={
                  <SearchOutlinedIcon style={{ color: Colors.textColor }} />
                }
                onHandleInputChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <CustomTable headerData={dashboardHeader}>
              <TableDataUi />
            </CustomTable>
          </Box>
        </Card>
      </Box>
      {showRequestConfirmation && (
        <ConfirmationDialog
          open={showRequestConfirmation}
          handleClose={handleRequestConfirmationClose}
          title={"Request Confirmation"}
          subtitle={"Are you sure you want to confirmation?"}
          actions={{}}
          onCancel={handleRequestConfirmationClose}
          onYes={handleYes}
        />
      )}
    </Box>
  );
};

export default RequestDetails;
