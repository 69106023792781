// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminNotifications_allFilters__x709g {
    font-size: 15px !important;
    font-weight: 550px !important;
    color: #161616 !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Notifications/adminNotifications.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,yBAAyB;EAC3B","sourcesContent":[".allFilters {\n    font-size: 15px !important;\n    font-weight: 550px !important;\n    color: #161616 !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allFilters": `adminNotifications_allFilters__x709g`
};
export default ___CSS_LOADER_EXPORT___;
