import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TableCell,
  TableRow,
} from "@mui/material";
import { Colors } from "../../constants/Colors";
import Card from "../../components/Card";
import CustomTable from "../../components/Table";
import CustomButton from "../../components/Button";
import CustomInput from "../../components/Input";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { AvatarView } from "../../pages/UserView";
import { Checkbox } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTab } from "../../components/CustomTabs";

const label = { inputProps: { "aria-label": "switch" } };


const VeterianDetails = () => {
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      title: "Basic Info",
    },
    {
      id: 2,
      title: `Requests`,
    },
    {
      id: 3,
      title: `Service Villages`,
    },
  ];
  const [tab, setTab] = useState(tabs[0]);

  const BasicInfo = () => {
    return (
      <Box m={3}>
        <Typography
          fontFamily={"Poppins-Medium"}
          fontSize={13}
          color={Colors.textColor}
        >
          Basic Details
        </Typography>
        <Box m={2}>
          <Grid container justifyContent={"space-around"} alignItems={"center"}>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"ID"}
                value={"14648"}
                disabled={true}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Name"}
                value={"Mundrathi Vivek"}
                disabled={false}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Mobile Number"}
                value={"9392270687"}
                disabled={false}
                bgColor={Colors.white}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={4}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Email"}
                value={"Mundrathivivek@Gmail.Com"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"DOB"}
                value={"22 Aug 1978"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Date Of Joining"}
                value={"22 Aug 2022"}
                bgColor={Colors.white}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={4}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Address Line"}
                value={"Mohipura, Near Bsnl Office"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Village"}
                value={"Alimpuram"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Mandal"}
                value={"Junguon"}
                bgColor={Colors.white}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={4}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"District"}
                value={"Junguon"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"State"}
                value={"Telengane"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Pincode"}
                value={"502103"}
                bgColor={Colors.white}
              />
            </Grid>
          </Grid>
          <Grid
            container
            mt={4}
            justifyContent={"space-around"}
            alignItems={"center"}
          >
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Id Type"}
                value={"Pan Card"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}>
              <CustomInput
                placeholder={""}
                label={"Id Number"}
                value={"ESHR12348P6"}
                bgColor={Colors.white}
              />
            </Grid>
            <Grid item width={250}></Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  const VillageEnterpre = () => {
    return (
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item md={6}>
          <Card>
            <Box margin={2}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography
                    fontFamily={"Poppins-Medium"}
                    fontSize={13}
                    color={Colors.textColor}
                  >
                    Health Services
                  </Typography>
                </Grid>
              </Grid>

              <Box m={2}>
                <Grid container flexDirection={"column"} gap={1}>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      1. General Visit Doctor
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      2. Distocia
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      3. Surgical Suturing's
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <Box margin={2}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Typography
                    fontFamily={"Poppins-Medium"}
                    fontSize={13}
                    color={Colors.textColor}
                  >
                    Health Services Areas
                  </Typography>
                </Grid>
                <Grid item>
                  <CustomButton
                    title={"+ Add Village"}
                    backgroundColor={Colors.headerColor}
                    textColor={Colors.white}
                    width={96}
                    height={22}
                  />
                </Grid>
              </Grid>

              <Box m={2}>
                <Grid container flexDirection={"column"} gap={1}>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      1. Alimpur, Mandal, Jungoun, Telangana
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      2. Alimpur, Mandal, Jungoun, Telangana
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      fontFamily={"Poppins-Regular"}
                      color={Colors.textColor}
                      fontSize={13}
                    >
                      3. Alimpur, Mandal, Jungoun, Telangana
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const Requests = () => {
    const tableHeader = [
      {
        id: 1,
        title: "Req id",
      },
      {
        id: 2,
        title: "Req by",
      },
      {
        id: 3,
        title: "Req on",
      },
      {
        id: 4,
        title: "Req name",
      },
      {
        id: 5,
        title: "Livestock",
      },
      {
        id: 6,
        title: "Location",
      },
      {
        id: 7,
        title: "Status",
      },
    ];

    const tableData = [
      {
        id: "402563",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402564",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402565",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402566",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402567",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402568",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
      {
        id: "402569",
        reqOn: "22 Aug 2022",
        reqByName: "Sujith S",
        reqByRole: "Farmer",
        reqName: "General Visit Doctor",
        liveStock: "Buffalo",
        reqToRole: "Farmer",
        reqToName: "Dr. Manish Kushwah",
        location: "Lalapeta",
        status: "Accepted",
      },
    ];

    const TableDataUi = () => {
      return tableData.map((row) => (
        <TableRow
          key={row.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell>
            <Typography
              fontFamily={"Poppins-Medium"}
              color={Colors.headerColor}
              fontSize={12}
              sx={{ textDecorationLine: "underline", cursor: "pointer" }}
            >
              {row.id}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography
              fontFamily={"Poppins-Regular"}
              color={Colors.textColor}
              fontSize={12}
            >
              {row.reqOn}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography
              fontFamily={"Poppins-Medium"}
              color={Colors.headerColor}
              fontSize={13}
              sx={{ textDecorationLine: "underline", cursor: "pointer" }}
              onClick={() => {
                navigate("/UserView/farmers");
              }}
            >
              {row.reqByName}
            </Typography>
            <Typography
              fontFamily={"Poppins-Regular"}
              color={Colors.textColor}
              fontSize={13}
            >
              {row.reqByRole}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography
              fontFamily={"Poppins-Regular"}
              color={Colors.textColor}
              fontSize={12}
            >
              {row.reqName}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              fontFamily={"Poppins-Regular"}
              color={Colors.textColor}
              fontSize={12}
            >
              {row.liveStock}
            </Typography>
          </TableCell>

          <TableCell>
            <Typography
              fontFamily={"Poppins-Regular"}
              color={Colors.textColor}
              fontSize={12}
            >
              {row.location}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography
              fontFamily={"Poppins-Medium"}
              color={Colors.headerColor}
              fontSize={13}
            >
              {row.location}
            </Typography>
          </TableCell>
        </TableRow>
      ));
    };
    return (
      <Box m={3}>
        <Box mt={2}>
          <CustomTable headerData={tableHeader} tableData={tableData}>
            <TableDataUi />
          </CustomTable>
        </Box>
      </Box>
    );
  };

  const AceVillages = () => {
    return (
      <Box m={2}>
        <Grid
          container
          mt={4}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item width={250}>
            <CustomInput
              placeholder={""}
              label={"State"}
              disable={true}
              value={"Telengana"}
            />
          </Grid>
          <Grid item width={250}>
            <CustomInput
              placeholder={""}
              label={"District"}
              disable={true}
              value={"Alimpuram"}
            />
          </Grid>
          <Grid item width={250}>
            <CustomInput
              placeholder={""}
              label={"Mandal"}
              disable={true}
              value={"Junguon"}
            />
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Grid item width={250}>
            <CustomInput
              placeholder={"Search"}
              leftIcon={
                <SearchOutlinedIcon style={{ color: Colors.textColor }} />
              }
            />
          </Grid>
        </Grid>
        <Box mt={1}>
          <Typography
            fontFamily={"Poppins-Medium"}
            fontSize={13}
            color={Colors.textColor}
          >
            Animals
          </Typography>
        </Box>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Grid item>
                <Checkbox
                  {...label}
                  size={"small"}
                  sx={{
                    color: Colors.textColor,
                    "&.Mui-checked": {
                      color: Colors.headerColor,
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  fontFamily={"Poppins-Medium"}
                  color={Colors.textColor}
                  fontSize={13}
                >
                  Village1
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box>
      <Grid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography
            fontFamily={"Poppins-Medium"}
            fontSize={20}
            color={Colors.textColor}
          >
            Veterinarians View
          </Typography>
          <Typography
            fontFamily={"Poppins-Regular"}
            fontSize={13}
            color={Colors.textColor}
          >
            Partners  Veterinarians  View
          </Typography>
        </Grid>
      </Grid>

      <Box mt={2}>
        <Card>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <AvatarView
                name={"Mundrathi Vivek"}
                designation={"Veterinarian : Mvsc, Bvsc"}
                nameColor={Colors.textColor}
              />
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box mt={2}>
        <Card>
          <CustomTab data={tabs} tab={tab} setTab={setTab} />
          {tab.id === 1 && <BasicInfo />}
          {tab.id === 2 && <Requests />}
          {tab.id === 3 && <AceVillages />}
        </Card>
        {tab.id === 1 && <VillageEnterpre />}
      </Box>
    </Box>
  );
};

export default VeterianDetails;
