// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_containerBox__IMX4R {
  width: 7px;
  height: 7px;
  padding: 2px;
  color: #b1040e;
  cursor: pointer;
}
.notification_notificationNumber__vV3Kb {
  font-size: 8px !important;
  color: #fff !important;
}
.notification_heading__kYCpX {
  font-size: 18px !important;
  color: #000000 !important;
}
.notification_divider__-\\+xd0 {
  font-size: 14px !important;
  color: black;
  margin-top: 5px !important;
}
.notification_notificationContainer__HmKMZ {
  box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px !important;
  padding: 15px 10px !important;
  border-radius: 5px !important;
  background-color: #fff !important;
  width: 350px !important;
}
.notification_greenDot__H7cAu {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.notification_notification__c5QPx {
  font-size: 15px !important;
  color: #b1040e !important;
  margin: 0px !important;
}
.notification_notificationSubHeading__Jn4Y9 {
  font-size: 13px !important;
  color: #013047 !important;
  margin-top: 5px !important;
  /* margin: 0px !important; */
  margin-bottom: 0px;
}
.notification_notificationTime__bgC-V {
  font-size: 13px !important;
  color: #013047 !important;
  margin: 0px !important;
}

.notification_content__YUybh {
  position: absolute !important;
  top: 18px !important;
  right: 31px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/notification.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;AAC3B;AACA;EACE,0BAA0B;EAC1B,YAAY;EACZ,0BAA0B;AAC5B;AACA;EACE,4DAA4D;EAC5D,6BAA6B;EAC7B,6BAA6B;EAC7B,iCAAiC;EACjC,uBAAuB;AACzB;AACA;EACE,UAAU;EACV,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,0BAA0B;EAC1B,4BAA4B;EAC5B,kBAAkB;AACpB;AACA;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,sBAAsB;AACxB","sourcesContent":[".containerBox {\n  width: 7px;\n  height: 7px;\n  padding: 2px;\n  color: #b1040e;\n  cursor: pointer;\n}\n.notificationNumber {\n  font-size: 8px !important;\n  color: #fff !important;\n}\n.heading {\n  font-size: 18px !important;\n  color: #000000 !important;\n}\n.divider {\n  font-size: 14px !important;\n  color: black;\n  margin-top: 5px !important;\n}\n.notificationContainer {\n  box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px !important;\n  padding: 15px 10px !important;\n  border-radius: 5px !important;\n  background-color: #fff !important;\n  width: 350px !important;\n}\n.greenDot {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n}\n.notification {\n  font-size: 15px !important;\n  color: #b1040e !important;\n  margin: 0px !important;\n}\n.notificationSubHeading {\n  font-size: 13px !important;\n  color: #013047 !important;\n  margin-top: 5px !important;\n  /* margin: 0px !important; */\n  margin-bottom: 0px;\n}\n.notificationTime {\n  font-size: 13px !important;\n  color: #013047 !important;\n  margin: 0px !important;\n}\n\n.content {\n  position: absolute !important;\n  top: 18px !important;\n  right: 31px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBox": `notification_containerBox__IMX4R`,
	"notificationNumber": `notification_notificationNumber__vV3Kb`,
	"heading": `notification_heading__kYCpX`,
	"divider": `notification_divider__-+xd0`,
	"notificationContainer": `notification_notificationContainer__HmKMZ`,
	"greenDot": `notification_greenDot__H7cAu`,
	"notification": `notification_notification__c5QPx`,
	"notificationSubHeading": `notification_notificationSubHeading__Jn4Y9`,
	"notificationTime": `notification_notificationTime__bgC-V`,
	"content": `notification_content__YUybh`
};
export default ___CSS_LOADER_EXPORT___;
