// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Poppins-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Poppins-Thin.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Poppins-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Poppins-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./assets/fonts/Poppins-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./assets/fonts/Poppins-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./assets/fonts/Poppins-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
@font-face {
  font-family: 'Poppins-Thin';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}
@font-face {
  font-family: 'Poppins-SemBiold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
}
@font-face {
  font-family: 'Poppins-Ebold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
}
@font-face {
  font-family: 'Poppins-Dark';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_6___});
}
a {
  text-decoration: none;
}

p, span, h1, h2, h3, h4, h5, h6{
  font-family: 'Poppins-Regular' !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,8BAA8B;EAC9B,4CAA8C;AAChD;AACA;EACE,2BAA2B;EAC3B,4CAA2C;AAC7C;AACA;EACE,6BAA6B;EAC7B,4CAA6C;AAC/C;AACA;EACE,+BAA+B;EAC/B,4CAA+C;AACjD;AACA;EACE,2BAA2B;EAC3B,4CAA2C;AAC7C;AACA;EACE,4BAA4B;EAC5B,4CAAgD;AAClD;AACA;EACE,2BAA2B;EAC3B,4CAA4C;AAC9C;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["body {\n  margin: 0;\n}\n\n@font-face {\n  font-family: 'Poppins-Regular';\n  src: url('./assets/fonts/Poppins-Regular.ttf');\n}\n@font-face {\n  font-family: 'Poppins-Thin';\n  src: url('./assets/fonts/Poppins-Thin.ttf');\n}\n@font-face {\n  font-family: 'Poppins-Medium';\n  src: url('./assets/fonts/Poppins-Medium.ttf');\n}\n@font-face {\n  font-family: 'Poppins-SemBiold';\n  src: url('./assets/fonts/Poppins-SemiBold.ttf');\n}\n@font-face {\n  font-family: 'Poppins-Bold';\n  src: url('./assets/fonts/Poppins-Bold.ttf');\n}\n@font-face {\n  font-family: 'Poppins-Ebold';\n  src: url('./assets/fonts/Poppins-ExtraBold.ttf');\n}\n@font-face {\n  font-family: 'Poppins-Dark';\n  src: url('./assets/fonts/Poppins-Black.ttf');\n}\na {\n  text-decoration: none;\n}\n\np, span, h1, h2, h3, h4, h5, h6{\n  font-family: 'Poppins-Regular' !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
